export const useFoodStore = defineStore('food', () => {
  const $api = useApi();

  const allFoods = ref([]);
  const activeFood = ref();
  const activeFoodPortions = ref();
  const activeFoodNutrients = ref();

  const totalFoods = ref(0);
  const totalPages = ref(1);
  const MAX_FETCH_ITEMS = 15;

  async function fetchAllFoods(data?: Object = {}) {
    const foods = await $api.post(`/food/all`, data);
    if (foods.data) {
      allFoods.value = foods.data;
      totalFoods.value = foods.count;
      totalPages.value = foods.totalPages;
    }
  }

  async function fetchDetailedFood(foodId: string) {
    const { data } = await $api.get(`/food/${foodId}`);
    activeFood.value = data;
  }

  async function fetchActiveFoodPortions() {
    try {
      const { data } = await $api.get(`/food/${activeFood.value.id}/portions`);
      activeFoodPortions.value = data;
    } catch (e) {}
  }

  async function fetchActiveFoodNutrients() {
    try {
      const { data } = await $api.get(`/food/${activeFood.value.id}/nutrients`);
      const customOrder = [
        'macro',
        'vitamin',
        'mineral',
        'fatty-acid',
        'amino-acid',
        'cholesterol',
        'micro',
      ];
      activeFoodNutrients.value = data.sort(
        (a, b) => customOrder.indexOf(a.type) - customOrder.indexOf(b.type),
      );
    } catch (e) {}
  }

  async function createFood(data: Record<string, string>) {
    const { data: foodCreated } = await $api.post(`/food`, data);
    if (foodCreated) {
      allFoods.value.push(foodCreated);
    }
  }

  async function updateActiveFood(data: Record<string, string>) {
    const { data: foodUpdated } = await $api.put(
      `/food/${activeFood.value.id}`,
      data,
    );
    if (foodUpdated) {
      activeFood.value = foodUpdated;
      const foodToUpdateIndex = allFoods.value.findIndex(
        (n) => n.id === foodId,
      );
      allFoods.value[foodToUpdateIndex] = {
        ...allFoods.value[foodToUpdateIndex],
        ...data,
      };
    }
  }

  return {
    MAX_FETCH_ITEMS,
    allFoods,
    activeFood,
    activeFoodPortions,
    activeFoodNutrients,
    totalFoods,
    totalPages,
    fetchAllFoods,
    fetchDetailedFood,
    fetchActiveFoodPortions,
    fetchActiveFoodNutrients,
    createFood,
    updateActiveFood,
  };
});
